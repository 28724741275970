const routes = [
  {
    index: true,
    label: 'Christian Baun',
    path: '/',
  },
  {
    label: 'Veröffentlichungen',
    path: '/veroeffentlichungen',
  },
  {
    label: 'Vorlesungen',
    path: '/vorlesungen',
  },
  {
    label: 'Abschlussarbeiten',
    path: '/abschlussarbeiten',
  },
  {
    label: 'Exkursionen',
    path: '/exkursionen',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
