import React from 'react';
import { Link } from 'react-router-dom';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/christian.jpg`} alt="" />
      </Link>
      <header>
        <h2>Prof. Dr. Christian Baun</h2>
        <p><a href="mailto:christianbaun@fb2.fra-uas.de">christianbaun@fb2.fra-uas.de</a></p>
        <p>Frankfurt University of Applied Sciences</p>
        <p>(1971-2014: Fachhochschule Frankfurt am Main)</p>
      </header>
    </section>

    <section id="footer">
      <img src={`${PUBLIC_URL}/images/fuas-logo.png`} alt="" /><br /><br />
      <p className="copyright">
        <span><a href="mailto:christianbaun@fb2.fra-uas.de">Prof. Dr. Christian Baun</a></span><br />
        <span><a href="https://www.frankfurt-university.de/">Frankfurt University of Applied Sciences</a></span><br />
        (1971-2014: Fachhochschule Frankfurt am Main)
        <span><a href="https://www.frankfurt-university.de/de/hochschule/fachbereich-2-informatik-und-ingenieurwissenschaften/willkommen-am-fb-2/">FB 2: Informatik und Ingenieurwissenschaften</a></span><br />
        Stand: 12.10.2021
      </p>
    </section>
  </section>
);

export default SideBar;
